var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-8",attrs:{"elevation":"4"}},[_c('v-card-title',{staticClass:"pb-6 d-flex flex-row justify-space-between"},[_c('h6',{staticClass:"font-weight-bold"},[_c('i18next',{attrs:{"translation":_vm.$t('heading.hosting.summary.nameserverCard.mainDomain.headline')},scopedSlots:_vm._u([{key:"domain",fn:function(){return [_c('span',{staticClass:"font-weight-light ml-1"},[_vm._v(" "+_vm._s(_vm.splitName()[1])+" ")])]},proxy:true}])})],1)]),(_vm.loading)?_c('v-card-text'):(
      _vm.serverAccount.service_dns_server_enabled
      && _vm.serverAccount.plan_settings.dns_nameservers_notification_enabled
      && _vm.nameservers.length
    )?_c('v-card-text',[_c('h6',[_vm._v(" "+_vm._s(_vm.$t('heading.hosting.summary.nameserverCard.nameservers.headline'))+" "),(_vm.resolvingNs)?_c('v-progress-circular',{attrs:{"size":20,"color":"primary","indeterminate":""}}):(_vm.unresolvedDomains.length || _vm.misconfiguredDomains.length)?_c('v-menu',{staticClass:"ml-2",attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"24px","origin":"top center","min-width":"400px","max-width":"400px","nudge-left":_vm.$vuetify.rtl ? '-180px' : '180px',"content-class":"v-tooltip","close-on-content-click":false,"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":"","text":"","color":"error"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error","size":"20"}},[_vm._v(" $alertcircle ")])],1)]}}])},[(_vm.unresolvedDomains.length)?_c('div',{staticClass:"px-4 py-4"},[_c('p',[_vm._v(_vm._s(_vm.$t('tooltip.hosting.summary.nameservers.notResolve'))+":")]),_c('ul',_vm._l((_vm.unresolvedDomains),function(item,index){return _c('nameserver-list-item',{key:item + index,attrs:{"item":item,"index":index}})}),1)]):_vm._e(),(_vm.misconfiguredDomains.length)?_c('div',{staticClass:"px-4 py-4"},[_c('p',[_vm._v(_vm._s(_vm.$t('tooltip.hosting.summary.nameservers.notPointed'))+":")]),_c('ul',_vm._l((_vm.misconfiguredDomains),function(item,index){return _c('nameserver-list-item',{key:item + index,attrs:{"item":item,"index":index}})}),1)]):_vm._e()]):_vm._e()],1),_c('p',{staticClass:"pt-1 mb-2"},[_vm._v(" "+_vm._s(_vm.$t('heading.hosting.summary.nameserverCard.nameservers.text'))+" ")]),_c('ul',[_vm._l((_vm.nameservers),function(item,index){return [(!_vm.loading)?_c('nameserver-list-item',{key:(item + " + " + index),attrs:{"item":item,"index":index}}):_c('v-skeleton-loader',{key:(item + " + " + index),staticClass:"mt-2",attrs:{"type":"text","width":"200"}})]})],2)]):(_vm.dnsRecords.length)?_c('v-card-text',[_c('h6',[_vm._v(" "+_vm._s(_vm.$t('heading.hosting.summary.nameserverCard.dnsRecords.headline'))+" "),(_vm.resolvingNs)?_c('v-progress-circular',{attrs:{"size":20,"color":"primary","indeterminate":""}}):(_vm.unresolvedDomains.length || _vm.misconfiguredDomains.length)?_c('v-menu',{staticClass:"ml-2",attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"24px","origin":"top center","min-width":"400px","max-width":"400px","nudge-left":_vm.$vuetify.rtl ? '-180px' : '180px',"content-class":"v-tooltip","close-on-content-click":false,"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":"","text":"","color":"error"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error","size":"20"}},[_vm._v(" $alertcircle ")])],1)]}}])},[(_vm.unresolvedDomains.length)?_c('div',{staticClass:"px-4 py-4"},[_c('p',[_vm._v(_vm._s(_vm.$t('tooltip.hosting.summary.dns.notResolve'))+":")]),_c('ul',_vm._l((_vm.unresolvedDomains),function(item,index){return _c('nameserver-list-item',{key:item + index,attrs:{"item":item,"index":index}})}),1)]):_vm._e(),(_vm.misconfiguredDomains.length)?_c('div',{staticClass:"px-4 py-4"},[_c('p',[_vm._v(_vm._s(_vm.$t('tooltip.hosting.summary.nameservers.notResolve'))+":")]),_c('ul',_vm._l((_vm.misconfiguredDomains),function(item,index){return _c('nameserver-list-item',{key:item + index,attrs:{"item":item,"index":index}})}),1)]):_vm._e()]):_vm._e()],1),_c('p',{staticClass:"pt-1 mb-2"},[_vm._v(" "+_vm._s(_vm.$t('heading.hosting.summary.nameserverCard.dnsRecords.text'))+": ")]),_c('ul',[_vm._l((_vm.dnsRecords),function(item,index){return [(!_vm.loading)?_c('nameserver-list-item',{key:("dnsRecord" + index),attrs:{"item":item,"index":index}}):_c('v-skeleton-loader',{key:("dnsRecord" + index),staticClass:"mt-2",attrs:{"type":"text","width":"200"}})]})],2)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }